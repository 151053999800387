import {useEffect, useState} from 'react';

const SignupForm = (setSubmitInProgress) => {
        let tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)
            tomorrow.setHours(0,0,0,0)
        const [formData, setFormData] = useState({
            name: '',
            phone: '',
            email: '',
            selectionType: 0, selectedDate: tomorrow

        });

        const [error, setError] = useState('Please fill out all fields');
        const [result, setResult] = useState(null);

        useEffect(() => {
            // console.log('useEffect called');
            validateFields();
        }, [formData]);

        const handleChange = (e) => {
            //console.log("handleChange called");
            const {name, value} = e.target;
            //console.log(name + " " + value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: name === 'phone' ? formatPhoneNumber(value) : value,
            }));
            //console.log(JSON.stringify(formData))
        };

        const validateNotEmpty = (text) => {
            const textRegex = /^.{3,}$/;
            return textRegex.test(text);
        };

        const validateEmail = (email) => {
            // Simple email validation using a regular expression
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        const formatPhoneNumber = (input) => {
            // Remove non-numeric characters
            const numericInput = input.toString().replace(/\D/g, '');
            // Use a regular expression to format the phone number
            return validateOptionalPhoneNumber(input) ? numericInput.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : input;
        };

        const validateOptionalPhoneNumber = (input) => {
            // Use a regular expression to check if the phone number is valid
            return input.length === 0 || /^[2-9]\d{9}$/.test(input.toString().replace(/\D/g, ''));
        };

        const validateDate = (input) => {
            return tomorrow <= input;
        };

        const validateFields = () => {
            if (!validateNotEmpty(formData.name) || !validateNotEmpty(formData.message)) {
                setError('Please fill out all fields');
                return false;
            }
            if (!validateOptionalPhoneNumber(formData.phone)) {
                setError('Please enter a valid phone number');
                return false;
            }
            if (!validateEmail(formData.email)) {
                setError('Please enter a valid email address');
                return false;
            }
            if (!validateDate(formData.selectedDate)) {
                setError('Please choose a date in the future');
                return false;
            }
            setError('');
            return true;
        }

        const handleSubmit = () => {
            setSubmitInProgress(true);
            if (!validateFields()) {
                return false;
            }

            const apiUrl = 'https://tmfhnloi22ub2rc6lkvnm2ngpy0auzst.lambda-url.us-east-1.on.aws/';
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    setResult('Thank you.  Your request has been submitted.');
                    return response.json();
                })
                .catch(err => {
                    console.error(err);
                    setResult('An Error has occurred.  Please contact us to complete your request.');
                }).finally(() => {
                setSubmitInProgress(false)
            });
        };

        return {formData, handleChange, handleSubmit, validateFields, error, result};
    }
;

export default SignupForm;
