import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Links = (props) => (
    <>
        <Header title="Links and Resources" sections={props.sections}/>
        <Container>

            <div>
                <br/>
                <a href="https://www.facebook.com/profile.php?id=61556715404192">Follow us on Facebook</a><br/><br/>
                <a href="https://senecalakewine.com/">Seneca Lake Wine Trail</a><br/><br/>
                <a href="https://www.cayugawinetrail.com/">Cayuga Lake Wine Trail</a><br/><br/>
                <a href="https://www.keukawinetrail.com/">Keuka Lake Wine Trail</a><br/><br/>
                <a href="https://www.fingerlakesdesignateddrivers.com/">Finger Lakes Designated Drivers</a><br/><br/>
                <a href="http://mainstreetdrivers.com/">Main Street Drivers</a>
            </div>
        </Container>
    </>
);

export default Links;