import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Home = (props) => (
        <>
            <Header title="Home" sections={props.sections}/>
            <Container>
                <div className="text-center">
                    <picture>
                        <source media="(min-width: 1100px)" srcSet="/image/WineCountry.png"/>
                        <source media="(min-width: 610px)" srcSet="/image/WineCountryMedium.png"/>
                        <source media="(min-width: 240px)" srcSet="/image/WineCountrySmall.png"/>
                        <img src="/image/WineCountrySmall.png" alt="Fingerlakes Wine Country Landscape Image"/>
                    </picture>
                </div>
                <h1>Welcome to Finger Lakes Wine Concierge - Your Personalized Wine Tour Experience</h1>
                    🍇 Embark on a Tailored Wine Adventure 🍷

                    Are you ready to savor the finest wines in the most enchanting vineyards? Look no further! At Finger
                    Lakes Wine Concierge, we specialize in curating personalized wine tours, ensuring every sip is an
                    experience to remember.

                    <h2>Why Choose Finger Lakes Wine Concierge?</h2>
                    <h3>Tailored to Your Tastes 🔍</h3>
                    Our expert wine enthusiasts understand that every palate is unique. We work closely with you to
                    uncover
                    your wine preferences, from bold reds to crisp whites, and craft a personalized itinerary that
                    caters to
                    your
                    individual taste buds.

                    <h3>Exclusive Access 🔒</h3>
                    Discover hidden gems and exclusive tastings at boutique wineries, family-owned vineyards, and
                    renowned
                    estates. We open doors to experiences that go beyond the standard wine tour, ensuring you create
                    lasting
                    memories.

                    <h3>Stress-Free Planning 🚗</h3>
                    Leave the logistics to us! Our concierge service takes care of all the details, from transportation
                    to
                    reservations. Sit back, relax, and enjoy the scenic beauty and exceptional wines without worrying
                    about
                    the nitty-gritty.

                    <h2>How It Works</h2>
                    <ol>
                        <li>Personal Consultation:</li>
                        📞 Schedule a consultation with our wine experts to discuss your preferences, interests, and any
                        special requests.

                        <li>Custom Itinerary:</li>
                        🗺️ Receive a personalized itinerary crafted just for you, featuring a selection of wineries that
                        match
                        your taste and style.

                        <li>VIP Treatment:</li>
                        🌟 Enjoy VIP treatment at each winery, from private tastings to behind-the-scenes tours, ensuring
                        an
                        unforgettable experience.

                        <li>Seamless Logistics:</li>
                        🥂 Our team takes care of all the logistics, including transportation, reservations, and any
                        additional
                        services you may need.
                    </ol>
                    <h2>Start Your Wine Journey Today</h2>
                    🍾 Ready to indulge in a one-of-a-kind wine tour experience? Contact us to schedule your consultation
                    and
                    let Finger Lakes Wine Concierge guide you through the world of exceptional wines and breathtaking
                    vineyards.
            </Container>
        </>
)
;
export default Home;