import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const About = (props) => (
    <>
        <Header title="About" sections={props.sections}/>
        <Container>
            <div>
                <h1>About Finger Lakes Wine Concierge</h1>
                🍇 Our Passion for Fine Wines and Unforgettable Experiences

                Welcome to Finger Lakes Wine Concierge, where our love for exceptional wines and breathtaking landscapes
                converge to create personalized wine tour experiences like no other. Founded by a team of seasoned wine
                enthusiasts, we are on a mission to redefine the way you experience the world of wines.

                <h2>Our Story 📖</h2>
                At Finger Lakes Wine Concierge, our journey began with a shared passion for exploring the rich tapestry
                of vineyards and discovering the stories behind each bottle. What started as a personal quest for the
                perfect sip evolved into a mission to share these extraordinary experiences with fellow wine
                enthusiasts.

                <h2>Meet the Team</h2>
                <h3>Joshua and Rachelle</h3>
                <p>Your Personal Wine Curators</p>

                Our dedicated Tour Specialist, Joshua, is the magic behind the scenes. With an innate ability to curate
                tailored itineraries, Joshua ensures that each tour is a seamless blend of indulgence and discovery.
                <br/><br/>
                Rachelle brings warmth and enthusiasm to personalized wine tours and curating. With her natural ability
                to connect with people and keen eye for detail, she crafts unforgettable experiences tailored to each
                client's tastes. Rachelle's passion for wine and storytelling creates an immersive journey through
                vineyards and cellars, making complex wine concepts accessible and enjoyable for all.

                <h2>Our Philosophy</h2>
                <picture>
                    <source media="(min-width: 1100px)" srcSet="/image/GrapeVines.png"/>
                    <source media="(min-width: 240px)" srcSet="/image/GrapeVinesSmall.png"/>
                    <img src="/image/GrapeVines.png" alt="Grape Vines"/>
                </picture>
                <br/>
                Elevating Your Wine Experience

                At Finger Lakes Wine Concierge, we believe that every glass of wine tells a unique story. Our philosophy
                is rooted in curating experiences that go beyond the ordinary, offering you exclusive access to hidden
                gems, family-owned vineyards, and renowned estates. We strive to create a perfect harmony between your
                preferences and the rich diversity of the wine world.

                Join Us on the Journey
                Whether you're a seasoned wine connoisseur or a novice eager to explore, we invite you to join us on a
                journey through vineyards, valleys, and vintages. Let VinoVistas be your guide as you savor exceptional
                wines, breathtaking views, and the joy of discovery.
            </div>

        </Container>
    </>
);

export default About;