import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            Copyright 2024 Fingerlakes Wine Concierge
        </Typography>
    );
}

function Footer() {

    return (
        <Box component="footer" sx={{bgcolor: '#EEE1FC', py: 6}}>
            <Container maxWidth="lg">
                <div className="text-center">
                    <button style={{background: "#FFFFFF"}}><a href="/book" style={{textDecoration: "none"}}>🍷 Book Your Personalized Wine Tour Now</a>
                    </button>
                </div>
                <p align="center"></p>
                <div className="text-center"><a href="/contact" style={{textDecoration: "none"}}>📞 </a><a href="/contact">Contact
                    Us</a> | <a href="mailto:fingerlakeswineconcierge@gmail.com" style={{textDecoration: "none"}}>📧 </a><a
                    href="mailto:fingerlakeswineconcierge@gmail.com">Email Us</a> | <a href="/" style={{textDecoration: "none"}}>🌐 </a><a
                    href="/">Home</a> | <a href="/book" style={{textDecoration: "none"}}>🍷 </a><a
                    href="/book">Book Your Tour
                    Now</a> | <a href="https://www.facebook.com/profile.php?id=61556715404192" style={{textDecoration: "none"}}><img src="/image/facebook.png"
                                                                                       height="14px"/> </a><a
                    href="https://www.facebook.com/profile.php?id=61556715404192">Follow us on
                    Facebook</a>
                </div>
                <Copyright/>
            </Container>
        </Box>
    );
}

export default Footer;
