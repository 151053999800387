import Header from "../components/Header";
import SignupForm from "../components/SingupForm";
import Container from '@mui/material/Container';
import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const BookingForm = () => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const {formData, handleChange, handleSubmit, validateFields, error, result} = SignupForm(setSubmitInProgress);

    const handleDateChange = (date) => {
        let temp = Object.create(null);
        temp.target = Object.create(null);
        temp.target.name = 'selectedDate';
        temp.target.value = date.setHours(0, 0, 0, 0);
        handleChange(temp);
    };

    const handleButtonClick = () => {
        setIsSubmitDisabled(true);
        handleSubmit();
    };

    useEffect(() => {
        //console.log('data changed');
        if (!error) {
            setIsSubmitDisabled(false);
        }
    }, [error]);

    formData.subject = "booking_form";
    return (
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input type="text" name="name" className="full-width" value={formData.name} onChange={handleChange}/>
            </label>
            <label>
                Email:
                <input type="text" name="email" className="full-width" value={formData.email} onChange={handleChange}/>
            </label>
            <label>
                Phone Number (Optional):
                <input type="text" name="phone" className="full-width"
                       value={formData.phone}
                       onChange={handleChange}/>
            </label>
            <b><label>
                Choose an option:
            </label></b>
            <div className="radio-container">
                <label>Contact me to discuss details</label>
                <input
                    type="radio"
                    name="selectionType"
                    value="0"
                    defaultChecked={true}
                    onChange={handleChange}
                    className="radio-button"
                /></div>
            <div className="radio-container">
                <label>Provide details now</label> <input
                type="radio"
                name="selectionType"
                value="1"
                onChange={handleChange}
                className="radio-button"
            /></div>
            {(formData.selectionType === "1") ?
                <>
                    <hr/>
                    <label>Number of participants:</label>
                    <input type="number" id="count" name="count" min="1" max="25" defaultValue="1" pattern="\d*"/>
                    <hr/>
                    <b><label>
                        Which Lake(s) do you prefer:</label></b>
                    <div className="radio-container">
                        <label>Keuka</label>
                        <input type="checkbox" name="keuka" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Seneca</label>
                        <input type="checkbox" name="seneca" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Cayuga</label>
                        <input type="checkbox" name="cayuga" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Transportation:</label></b>
                    <div className="radio-container">
                        <label>Self transport</label>
                        <input type="radio" name="transport" className="radio-button"
                               value="self"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Limousine</label>
                        <input type="radio" name="transport" className="radio-button"
                               value="limo"
                               onChange={handleChange}/></div>
                    {/*<div className="radio-container">
                        <label className="radio-container">Boat</label>
                        <input type="radio" name="transport" className="radio-button"
                               value="boat"
                               disabled={true}
                               onChange={handleChange}/></div>*/}
                    <hr/>
                    <b><label>
                        Dry or Sweet:</label></b>
                    <div className="radio-container">
                        <label>Dry</label>
                        <input type="checkbox" name="dry" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Semi-Dry</label>
                        <input type="checkbox" name="semi" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Sweet</label>
                        <input type="checkbox" name="sweet" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Varietals:</label></b>
                    <div className="radio-container">
                        <label>Red</label>
                        <input type="checkbox" name="red" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">White</label>
                        <input type="checkbox" name="white" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Rosé</label>
                        <input type="checkbox" name="rose" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Sparkling</label>
                        <input type="checkbox" name="sparkling" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Styles:</label></b>
                    <div className="radio-container">
                        <label>Bold and robust</label>
                        <input type="checkbox" name="bold" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Light and crisp</label>
                        <input type="checkbox" name="light" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Sweet and dessert wines</label>
                        <input type="checkbox" name="dessert" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Earthy and complex</label>
                        <input type="checkbox" name="earthy" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Atmosphere:</label></b>
                    <div className="radio-container">
                        <label>Formal and educational</label>
                        <input type="checkbox" name="formal" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label>Casual and relaxed</label>
                        <input type="checkbox" name="casual" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Family friendly</label>
                        <input type="checkbox" name="family" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Dog friendly</label>
                        <input type="checkbox" name="dog" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Wheelchair accessible</label>
                        <input type="checkbox" name="wheelchair" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Lunch:</label></b>
                    <div className="radio-container">
                        <label>Restaurant onsite</label>
                        <input type="checkbox" name="onsitefood" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label>Picnic area onsite</label>
                        <input type="checkbox" name="picnic" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Other restaurants</label>
                        <input type="checkbox" name="offsitefood" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">None</label>
                        <input type="checkbox" name="nofood" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Additional Options:</label></b>
                    <div className="radio-container">
                        <label>Membership or Case club discounts</label>
                        <input type="checkbox" name="membership" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label>Includes wine glass or other gift</label>
                        <input type="checkbox" name="glass" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Gift Shop onsite</label>
                        <input type="checkbox" name="giftshop" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Scenic views</label>
                        <input type="checkbox" name="scenic" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <div className="radio-container">
                        <label className="radio-container">Overnight accommodations</label>
                        <input type="checkbox" name="hotel" className="radio-button"
                               value="true"
                               onChange={handleChange}/></div>
                    <hr/>
                    <b><label>
                        Preferred Date:
                    </label></b><DatePicker
                    selected={formData.selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                />
                    <label>
                        Additional information:</label>
                    <textarea name="details" className="full-width" value={formData.message}
                              onChange={handleChange}/>


                </> : ''
            }


            <button type="button" onClick={handleButtonClick} disabled={isSubmitDisabled}>Submit</button>
            {error && <p style={{color: 'red'}}>{error}</p>}
            {(!error || result) && <p>{result}</p>}
        </form>
    );
};

const Book = (props) => (
    <>
        <Header title="Personalized Wine Tours" sections={props.sections}/>
        <Container>
            <div>
                <div align="center"><a href="tel:5854207946">Call</a> or&nbsp;
                    <a href="sms:5854207946">text us</a> at <a href="tel:5854207946">(585) 420-7946</a></div>
                <div align="center"><a href="https://www.facebook.com/profile.php?id=61556715404192">Message us
                    on
                    Facebook</a></div>
                <br/><br/>
                <div align="center">Or fill out and submit the form below</div>
                <BookingForm/>
            </div>
        </Container>
    </>
);

export default Book;