// MyFormLogic.js
import {useState} from 'react';

const MyFormLogic = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        subject: '',
    });

    const [error, setError] = useState('Please fill out all fields');
    //const [change, setChange] = useState(null);
    const [result, setResult] = useState(null);

    const handleChange = (e) => {
        /*if (validateFields()) {
          setChange(true);
        }*/
        validateFields();
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };

    const validateNotEmpty = (text) => {
        const textRegex = /^.{3,}$/;
        return textRegex.test(text);
    };

    const validateEmail = (email) => {
        // Simple email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateFields = () => {
        //console.log('validateFields called');
        if (!validateNotEmpty(formData.name) || !validateNotEmpty(formData.message)) {
            setError('Please fill out all fields');
            return false;
        }
        if (!validateEmail(formData.email)) {
            setError('Please enter a valid email address');
            return false;
        }
        setError('');
        return true;
    }

    const handleSubmit = () => {
        //e.preventDefault();
        //setChange(false);
        if (!validateFields()) {
            return false;
        }

        const apiUrl = 'https://tmfhnloi22ub2rc6lkvnm2ngpy0auzst.lambda-url.us-east-1.on.aws/';
        //const apiUrl = 'https://httpstat.us/500';
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.text();
            })
            .then(data => {
                console.log('Server response:', data);
                setResult('Thank You.  Your message has been recorded.');
            })
            .catch(error => {
                console.error('Error:', error);
                setError('An Error has occurred.  Please try again later or contact us using a different method.');
            });
    };

    return {formData, handleChange, handleSubmit, error, result};
};

export default MyFormLogic;
